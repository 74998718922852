/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {
    .hero-text-box {
        width: 100%;
        padding: 0 2%;
    }

    .hero-text-box-shortened {
        width: 100%;
        padding: 0 2%;
    }

    .hero-text-box-noti {
        width: 100%;
        padding: 0 2%;
    }

    .row { padding: 0 2%; }
}


/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {
    body { font-size: 18px; }
    section { padding: 60px 0; }

    .long-copy {
        width: 80%;
        margin-left: 10%;
    }

    .steps-box { margin-top: 10px; }
    .steps-box:last-child { margin-top: 10px; }
    .works-steps { margin-bottom: 40px; }
    .works-step:last-of-type { margin-bottom: 60px; }

    .app-screen { width: 50%; }

    .icon-small {
        width: 17px;
        margin-right: 5px;
    }

    .city-feature { font-size: 90%; }

    .plan-box {
        width: 100%;
        margin-left: 0%;
    }

    .plan-price { font-size: 250%; }
    .contact-form { width: 80%; }

    .app-title-container-2 {
      margin-left: 2%;
    }


}

/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
    body { font-size: 16px; }
    section { padding: 30px 0; }

    .row {
      padding: 0 4%;
    }

    .hero-text-box {
      padding: 0 4%;
    }

    .hero-text-box-shortened {
      padding: 0 4%;
    }

    .hero-text-box-noti {
      padding: 0 4%;
      top: 70%;

    }

    .col {
        width: 100%;
        margin: 0 0 4% 0;
    }

    .main-nav { display: none; }
    .main-nav-dark { display: none; }

    /* .main-nav-dark {
        color: #1e1e1e;
    }

    .main-nav-dark li a:link,
    .main-nav-dark li a:visited {
        color: #1e1e1e;
    } */

    .mobile-nav-icon {display: inline-block;}

    .mobile-nav {
        float: left;
        list-style: none;
        margin-top: 30px;
        margin-right: 80px;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 10px 10px;
        position: absolute;
        right: 0;
        z-index: 1;
        border: 1px solid #BEBEBE;
        background-color: white;
    }

    .mobile-nav li {
        display: block;
    }

    .mobile-nav li a:link,
    .mobile-nav li a:visited {
        display: block;
        border: 0;
        padding: 10px 0;
        font-size: 120%;
        color: #1e1e1e;
    }

    /* inside the app*/

    .mobile-app-nav-icon {display: inline-block;}
    .side-nav {display: none; }

    /*app containers*/

    .floating-container {
      width: 92%;
      margin: 2% 2% 0% 2%;
    }

    .floating-container-2 {
      width: 92%;
      margin: 0% 2% 0% 2%;
    }

    .standard-floating-container {
        width: 92%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-container {
        width: 92%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-container h1 {
        width: 100%;
        color: #000;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container p {
        width: 100%;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-header-container {
        width: 92%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-header-container h1 {
        width: 100%;
        color: #000;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-header-container p {
        width: 100%;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-header-container-header-text-dashboard {
      width: 40%;
      float: left;
      display: inline-block;
      margin: -12px 0 0 6%;
      padding: 25px 0% 5% 0%;
      color: rgba(150,150,150,1);
    }

    .app-container-card {

        background-color: #fff;
        width: 92%;
        height: auto;
        margin: 2% 2% 2% 2%;
        padding: 0% 3% 30px 3%;
        box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
    }

    .no-side {
      margin-left: 2%;
    }

    .app-container-2 {
        width: 92%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-container-2 h1 {
        width: 100%;
        color: #000;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-2 p {
        width: 100%;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-3 {
        width: 96%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-container-3 h1 {
        width: 100%;
        color: #000;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-3 p {
        width: 100%;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-4 {
        width: 96%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-container-4 h1 {
        width: 100%;
        color: #000;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-4 p {
        width: 100%;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-5 {
        width: 96%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .app-container-5 h1 {
        width: 100%;
        color: #000;
        margin-left: 0%;
        padding-left: 0;
    }

    .app-container-5 p {
        width: 100%;
        margin-left: 0%;
        padding-left: 0;
    }

    .filter-container {
        width: 92%;
        margin: 2% 2% 2% 2%;
        padding: 2% 2% 2% 2%;
    }

    .settings-container {
        background-color: #fff;
        width: 85%;
        height: auto;
        margin: 2% auto 2% auto;
        padding: 5% 5% 5% 5%;
    }

    .app-nav li {
      display: none;
    }

    .app-nav-2 li {
      display: none;
    }

    .mobile-app-nav-items {
        float: left;
        list-style: none;
        margin-top: 30px;
        margin-right: 60px;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 10px 10px;
        position: absolute;
        z-index: 1;

    }

    .mobile-app-nav-items li {
        display: block;
    }

    .mobile-app-nav-items li a:link,
    .mobile-app-nav-items li a:visited {
        display: block;
        border: 0;
        padding: 10px 0;
        font-size: 100%;
    }

    .sticky .main-nav { margin-top: 10px; }

    .sticky .main-nav li a:link,
    .sticky .main-nav li a:visited { padding: 10px 0; }
    .sticky .mobile-nav-icon { margin-top: 10px; }
    .sticky .mobile-nav-icon i { color: #555; }

    .search-bar-container {
      width: 25%;
      display: inline-block;
    }

    .search-results-container {
      width: auto;
      display: inline-block;
      position: absolute;
      margin: 2px 0% 0% 10px;
      padding: 3px 10px 3px 10px;
      background-color: #fff;
      border: 2px solid #BEBEBE;
      z-index: 1;
    }

    h1 { font-size: 180%; }
    h2 { font-size: 150%; }

    .long-copy {
        width: 100%;
        margin-left: 0%;
    }

    .app-screen { width: 40%; }
    .steps-box:first-child { text-align: center; }

    .works-step div {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        padding: 4px;
        font-size: 120%;
    }

    .works-step { margin-bottom: 20px; }
    .works-step:last-of-type { margin-bottom: 20px; }

    .steps-box-counselors {
        padding: 20px 5px;
    }
    .benefits-text {
        margin-bottom: 20px;
    }
    .about-content-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        /*position: absolute;*/

    }
    .about-text-box-1 {
        display: inline-block;
        margin-left: 20px;
        margin-top: 20px;
        width: 90%;
        float: center;
    }

    .about-text-box-2 {
        display: inline-block;
        margin-left: 20px;
        margin-top: 20px;
        width: 90%;
        float: center;
    }


    .about-image-container-1 {
        display: block;
        width: 100%;
        text-align: center;

    }

    .about-image-container-2 {
        display: block;
        width: 100%;
        text-align: center;
    }

    .about-image-1 {

        width: 200px;
    }

    .about-image-2 {

        width: 200px;
        margin-top: 20px;
    }

    .footer {
        padding-top: 20px;
    }

    .footer-nav {
        display: block;
        margin-top: 0px;
        margin-bottom: 30px;
        text-align: center;
        width: 100%;
    }

    .social-links {
        display: none;

    }

    .hero-text-box h1 {
        font-size: 240%;
        width: 100%;
    }

    .hero-text-box p {

        color: #fff;
        margin-top: -10px;
        margin-bottom:20px;
        width: 100%;
        height: auto;
    }

    .hero-text-box-shortened h1 {
        font-size: 240%;
        width: 100%;
    }

    .hero-text-box-shortened p {

        color: #fff;
        margin-top: -10px;
        margin-bottom:20px;
        width: 100%;
        height: auto;
    }

    .hero-text-box-noti h1 {
        font-size: 240%;
        width: 100%;
    }

    .hero-text-box-noti p {

        color: #fff;
        margin-top: -10px;
        margin-bottom:20px;
        width: 100%;
        height: auto;
    }


    .next-generation-container {
        top: 20%;
        left: 20%;
        transform: translate(-10%, -10%);
        width: 80%;
    }

    .next-generation-text-box {

        display: inline-block;
        margin-top: 30px;
        margin-right: 10px;
    }

    .next-generation-text {
        font-size: 130%;

    }

    .next-generation-title {
        font-size: 250%;
    }

    .connect-to-real-container {
      width: 85%;
      transform: translate(-25%, -55%);
    }

    .left-panel {
      width: 100%;
      padding: 2%;
      margin-bottom: 10px;
    }

    .right-panel {
      width: 100%;
      padding: 2%;
      border: none;
    }

    .section-cta {
        height: auto;
        position: static;
        width: 100%;
    }

    .cta-container {
      position: static;
      width: 100%;
      height: auto;
      transform: translate(0%, 0%);
      padding: 0px;
    }

    .cta-text-box {
      position: static;
      width: 100%;
      text-align: center;
      padding: 0px;

    }

    .cta-buttons {
      width: 100%;
      position: static;
      text-align: center;
      padding: 0px;
    }

    .philosophy-content-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        /*position: absolute;*/

    }
    .philosophy-text-box-1 {
        display: inline-block;
        margin-left: 20px;
        margin-top: 20px;
        width: 90%;
        float: center;
    }

    .philosophy-text-box-2 {
        display: inline-block;
        margin-left: 20px;
        margin-top: 20px;
        width: 90%;
        float: center;
    }

    .philosophy-text-box-3 {
        display: inline-block;
        margin-left: 20px;
        margin-top: 20px;
        width: 90%;
        float: center;
    }


    .philosophy-image-container-1 {
        display: block;
        width: 100%;
        text-align: center;

    }

    .philosophy-image-container-2 {
        display: block;
        width: 100%;
        text-align: center;
    }

    .philosophy-image-container-3 {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 20px;

    }

    .about-image-1 {

        width: 200px;
    }

    .about-image-2 {

        width: 200px;
        margin-top: 20px;
    }

    .about-image-3 {

        width: 200px;
    }

    .join-container {
        position: absolute;
        padding-left: 20px;
        padding-right: 0px;
        width: 100%;
    }

    .join-text-box {

        float: left;
        height: 100%;
        width: calc(100% - 220px);
    }

    .join-btn {
      margin-top: 50px;
      float: left;
      margin-left: 10px;
    }

    .join-btn-2 {
      float: left;
      margin-top: 10px
    }

    .profile-image {
        width: 80px;
        height: auto;
        margin-top: 1px;
        margin-left: 3%;
    }

    .profile-categories-container {
        width: 100%;
        height: 90px;
        text-align: center;
        margin-left: 18%;
        margin-right: auto;
        padding-right: 5%;

    }

    .profile-categories {

        width: 25%;
        display: inline-block;
        float: left;
        padding: 18px 12% 0 12%;
    }

    .follow-btn {

        display: none;
        margin-top: 60px;
        margin-right: auto;
        margin-left: auto;
        position: absolute;
        padding: 10px;
        font-size: 100%;
        border-radius: 20px;
        text-align: center;

    }

    .profile-side-container {

        background-color: #fff;
        width: 96%;
        height: auto;
        margin: 4% 2% 2% 2%;
        float: left;
        padding: 5% 3% 5% 3%;
    }

    .profile-name {
        font-size: 150%;
        text-align: center;
        margin-top: 10px;
    }
    .profile-links {

        list-style: none;
        text-align: center;
        display: table;
        width: 100%;
    }

    .profile-links li {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        padding: 5px;
    }

    .profile-descriptor {
        font-size: 80%;
        color: #BEBEBE;
        padding-bottom: 5px;
    }

    .profile-followers {
        width: 50%;
        display: inline-block;
    }

    .empty-state-container {

        width: 92%;
        height: 500px;
        margin: 2% 2% 2% 0%;
        padding: 5% 2% 5% 2%;
        text-align: center;

    }

    .empty-state-icon {
        width: 120px;
        height: auto;
    }

    .empty-state-title {
        margin-top: 20px;
        color: #969696;
    }

    .empty-state-text {
        margin-top: 20px;
        color: #969696;
    }

    .empty-state-button {
        margin-top: 30px;

    }

    .stackedViews {
      float: left;
      width: 100%;
      text-align: center;
      padding: 0 3%;
      margin-bottom: 20px;
    }

    .pricingPanels {
      float: left;
      width: 100%;
      text-align: center;
      padding: 25px 3%;
      margin-bottom: 20px;
    }

    .twoViewsWide {
      width: 100%;
      margin-bottom: 20px;
    }

    .sixViewsWide {
      width: 50%;
      margin-bottom: 20px;
    }

    .smallContainerLeft {
      width: 100%;
      margin-bottom: 20px;;
    }

    .largeContainerLeft {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0px;
    }

    .smallContainerRight {
      width: 100%;
      margin-bottom: 20px;
    }

    .largeContainerRight {
      width: 100%;
      margin-bottom: 20px;

    }

    .howItWorksLeft {
      width: 100%;
      margin-bottom: 20px;
    }

    .howItWorksRight {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 0px;
      margin-top: 0px;
    }

    .leftImage {
      margin-left: auto;
      margin-right: auto;
    }

    .rightImage {
      margin-left: auto;
      margin-right: auto;
    }

    .leftLargeImage {
      margin-left: auto;
      margin-right: auto;
    }

    .buttonContainer {
      margin: 10px auto;
    }

    .signup-name-container {

        width: 100%;
        clear: both;
        padding: 5px 3% 0px 0%;
    }

    .walkthrough-feature-image-container {
      width: 0%;
      height: 00%;
    }

    .walkthrough-feature-image {
      width: 0%;
      height: 0%;
    }

    .walkthrough-feature-text-container {
      width: 100%;
      height: 100%;
      padding: 30px;
    }

    .org-side-nav {

      width: 22%;
      margin: 2% 2% 2% -125%;

    }

    .sub-side-nav {
      width: 22%;
      margin: 2% 2% 2% -125%;
    }

    .main-adv-panel {
      width: 100%;
    }

    .glimpse-adv-panel {
      width: 100%;
    }

    .table-row-header {
      display: block;
      clear: both;
      padding-bottom: 50px;
      margin-bottom: 80px;
    }

    .category-icon-container {
      display: inline-block;
      float: left;
    }

    .table-row-header-content-4 {
      display: inline-block;
      float: left;
      padding: 0px 0px 0px 20px;
      width: 60%;
    }

    .logo {
      height: 25px;
      margin-top: 29px;
    }

    .logo-black {
      height: 15px;
      margin: 3px 0;
    }

    .partner-add-icon {
      height: 10px;
      margin-top: 13px;
    }

    .partner-logo {
      height: 19px;
      margin-top: 7px;
    }

    .partner-container {
      padding-left: 5px;
      padding-right: 2px;
      margin-right: 7px;
      margin-top: 5px;
    }

    .logo-nav {
      height: 24px;
      margin-top: 20px;
    }

    .partner-add-icon-2 {
      height: 15px;
      margin-top: 26px;
    }
    .advisee-side-info {
      width: 96%;
    }

    .side-nav-items-1 {
      list-style: none;
      width: 100%;
      text-align: center;
      margin: 0px;
      padding: 0px;
      display: block;
    }

    .side-nav-items-1 img {
      display: inline-block;
      margin: 15px 4%;
      height: 40px;
      width: 40px;
    }

    .adv-side-info {
      width: 96%;
    }

    .thin-side-nav {
      width: 96%;
    }
    .advisor-container-card {
      width: 96%;
    }

    .member-container-card {
      width: 96%;
    }

    .main-side-card {
      width: 96%;
    }

    .login-container {
      background-color: #fff;
      width: 82%;
      height: auto;
      border-radius: 2%;
      margin: auto auto;
      padding: 15% 0;
      transform: translate(0%, 8%);
    }

    .employer-signup-form {
      width: 82%;
    }

    .helpful-founder-image {
      width: 80%;
      height: auto;
      margin: 0 auto 0 auto;
      clear: both;
    }

    .name-container {
      width: 100%;
    }

    .container-left {
      width: 100%;
      padding: 0px;
    }

    .container-right {
      width: 100%;
      padding: 0px;
    }

    .row-date {
      display: block;
      float: none;
      clear: both;
    }

    .endorsement-name {
      float: left;
      width: calc(100% - 90px);
      padding-left: 10px;
    }

    .endorsement-pathway {
      float: none;
      width: 100%;
      clear: both;
      text-align: left;
      padding-top: 20px;
    }

    .app-title-container-1 {
      float: left;
      width: 33%;
      padding: 25px 0px 10px 0px;
      margin: 0px;
      text-align: center;
    }

    .app-title-container-2 {
      float: left;
      width: 33%;
      padding: 25px 0px 10px 0px;
      margin: 0px;
      text-align: center;
    }

    .app-title-container-1-of-2-1 {
      float: left;
      width: 50%;
      padding: 25px 0px 10px 0px;
      margin: 0px;
      text-align: center;
    }

    .app-title-container-1-of-2-2 {
      float: left;
      width: 50%;
      padding: 25px 0px 10px 0px;
      margin: 0px;
      text-align: center;
    }

    .profile-card-basic-info-container {
      width: 100%;
      float: none;
    }

    .profile-card-details-container {
      width: 100%;
      float: none;
    }

    .half-width {
      width: 100%;
    }

    .fixed-column-125 {
      float: none;
      width: 100%;
    }

    .fixed-column-140 {
      float: none;
      width: 100%;
    }

    .fixed-column-150 {
      float: none;
      width: 100%;
    }

    .calc-column-offset-100 {
      float: none;
      width: 100%;
    }

    .calc-column-offset-140 {
      float: none;
      width: 100%;
    }

    .calc-column-offset-195 {
      float: none;
      width: 100%;
    }

    .calc-column-offset-240 {
      float: none;
      width: 100%;
    }

    .modal {
      top: 50%;
      left: 50%;
      margin-right: -120%;
      transform: translate(-50%, -50%);
      width: 90%;
    }

    .modal-chat {
      width: 90%;
      max-height: calc(100vh - 70px);
    }

    .width-70-percent {
      width: 100%;
    }

    .purpose-container-1 {
      width: 100%;
    }

    .relative-column-33 {
      width: 100%;
    }

    .flex-container-mobile-column {
      flex-direction: column;
    }

    .shrink-width {
      padding-left: 20%;
      padding-right: 20%;
    }

    .top-margin-100-desktop {
      margin-top: 30px;
    }

    .centered-card-image {
      width: 100%;
      margin-right: auto;
      padding-left: 10%;
      padding-right: 10%;
    }

    .horizontally-centered-container {
      width: 92%;
    }

    .standard-container {
      width: 92%;
    }

    .standard-container-2 {
      width: 92%;
    }

    .video-container-3 {
      position: relative;
      width: 100%;
      height: 100px;
    }

    .video-iframe-4 {
      height: auto;
    }

    .menu-bottom-2 {
      margin-left: calc(100% - 200px);
    }
}

/* .login-container {
  background-color: #fff;
  width: 60%;
  height: 72%;
  border-radius: 2%;
  margin: auto auto;
  padding-top: 10%;
  transform: translate(0%, 8%);
} */



/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {
    section { padding: 25px 0; }
    .contact-form {width: 100%; }
}
